import React, { useState, useEffect, useCallback, useRef } from "react";
import Cropper from "react-easy-crop";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./BusinessSignUp.css";
import { ReactComponent as BusinessSignUpMaskLeft } from "../../assets/svgs/BusinessSignUpMaskLeft.svg";
import { ReactComponent as BusinessSignUpMaskRight } from "../../assets/svgs/BusinessSignUpMaskRight.svg";
import { ReactComponent as BusinessSignUpMaskMiddle } from "../../assets/svgs/BusinessSignUpMaskMiddle.svg";
import { ReactComponent as UserAddFilled } from "../../assets/svgs/UserAddFilled.svg";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import Overview from "../../Component/BusinessSignUp/Overview";
import SocialLink from "../../Component/BusinessSignUp/SocialLink";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import { businessRegister, getUserDetails } from "../../services/api";
import useEmailVerification from "../../hooks/common/useEmailverification";
import useUrlVerification from "../../hooks/common/useUrlVerification";
import GetCroppedImage from "../../Component/Common/GetCroppedImage";
import CentralCustomModal from "../../Component/Common/CentralModals/CentralCustomModal";
import {
  SHOW_ACTIVITY_INDICATOR,
  USER_DATA,
  BANNER_CONTENT,
} from "../../actions";

function BusinessSignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const countryList = useSelector((state) => state.countryData?.countryData);
  const [brandName, setBrandName] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessLogoError, setBusinessLogoError] = useState("");
  const [brandMobileCode, setBrandMobileCode] = useState(1);
  const [brandMobileNumber, setBrandMobileNumber] = useState("");
  const [brandEmail, setBrandEmail] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [brandImage, setBrandImage] = useState("");
  const [brandImageBase64, setBrandImageBase64] = useState("");
  const [emailError, setEmailError] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [socialList, setSocialList] = useState([
    {
      name: "Select an option",
      url: "",
      isSaved: false,
      assistiveText: "",
      descriptiveText: "",
    },
  ]);
  const { isVerified, verifyEmail } = useEmailVerification();
  const { isVerifiedUrl, verifyUrl } = useUrlVerification();
  const [isCropModalVisible, setIsCropModalVisible] = useState(false);
  const [toCropImage, setToCropImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await GetCroppedImage(
        toCropImage,
        croppedAreaPixels
      );
      console.log("donee", { croppedImage });
      setBrandImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    async function fetchData() {
      try {
        const imageBlob = await fetchImage(brandImage);
        const base64Data = await blobToBase64(imageBlob);
        setBrandImageBase64(base64Data);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }

    brandImage !== "" && fetchData();
  }, [brandImage]);

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  async function fetchImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  const onSubmitSignUp = async () => {
    if (
      brandName.trim() !== "" &&
      brandImage !== "" &&
      emailError === "" &&
      websiteError === "" &&
      socialList.every((item) => item.assistiveText === "")
    ) {
      //  dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });

      const foundCountry = countryList.find(
        (country) => country.id === brandMobileCode
      );
      let dialingCode = "44";
      if (foundCountry) {
        dialingCode = foundCountry.dialingCode;
      }
      let params = {
        id: 0,
        name: brandName,
        emailAddress: brandEmail,
        phone: `${dialingCode} ${brandMobileNumber}`,
        webSiteURL: brandUrl,
        vatNumber: vatNumber,
        countryId: brandMobileCode || 1,
        businessImage: {
          image: brandImageBase64,
          contentType: "png",
        },
        socialMedia: {
          id: 0,
          twitter: socialList.find((item) => item.name === "X")?.url || "",
          instagram:
            socialList.find((item) => item.name === "Instagram")?.url || "",
          linkedIn:
            socialList.find((item) => item.name === "LinkedIn")?.url || "",
          facebook:
            socialList.find((item) => item.name === "Facebook")?.url || "",
          tikTok: socialList.find((item) => item.name === "TikTok")?.url || "",
          youTube:
            socialList.find((item) => item.name === "YouTube")?.url || "",
        },
      };
      const result = await businessRegister(params);
      if (result?.status) {
        userData();
      } else {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
        if (result.response?.includes(".vat_number")) {
          dispatch({
            type: BANNER_CONTENT,
            payload: {
              text: "Vat number is not valid",
              headingText: "Error Creating Business Profile",
              showBanner: true,
              type: "Error",
              withClose: true,
              withIcon: true,
              position: "top",
              paddingLeft: "8px",
            parentContainerStyle: {
              top: "8px",
               zIndex: 15,
            },
            },
          });

         }else {
          dispatch({
            type: BANNER_CONTENT,
            payload: {
              text: result.response,
              headingText: "Error Creating Business Profile",
              showBanner: true,
              type: "Error",
              withClose: true,
              withIcon: true,
              position: "top",
              paddingLeft: "8px",
            parentContainerStyle: {
              top: "8px",
               zIndex: 15,
            },
            },
          });
        }
      }
    } else {
      if (brandName.trim() === "") {
        setBusinessNameError("Brand name required.");
      } else {
        setBusinessNameError("");
      }
      if (brandImage.trim() === "") {
        setBusinessLogoError("Profile image required.");
      } else {
        setBusinessLogoError("");
      }
    }
  };

  const userData = async () => {
    const result = await getUserDetails();
    if (result.status) {
      dispatch({ type: USER_DATA, payload: result.response });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      navigate("/Home");
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const ErrorCheck = (type, value) => {
    if (type === "email") {
      if (!isVerified && value !== "") {
        setEmailError("Email address entered is not valid.");
      } else {
        setEmailError("");
      }
    }

    if (type === "website") {
      if (!isVerifiedUrl && value !== "") {
        setWebsiteError("Website address entered is not valid.");
      } else {
        setWebsiteError("");
      }
    }
  };

  const renderCropEventBanner = () => {
    return (
      <div
        className="crop-event-banner-container crop-banner-for-square"
        style={{
          margin: "17px",
          width: "700px",
          height: "393.75",
          alignSelf: "center",
        }}
      >
        <Cropper
          // image="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
          image={toCropImage}
          crop={crop}
          zoom={zoom}
          minZoom={1}
          maxZoom={10}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          ref={imageRef}
        />

        <input
          style={{ width: "100%", marginTop: 24 }}
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value);
          }}
          className="zoom-range"
        />

        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
            marginBottom: 0,
            justifyContent: "flex-end",
          }}
        >
          <div className="generic-common-row-div">
            <ButtonPrimary
              label={"Use original"}
              onPress={() => {
                setIsCropModalVisible(false);
                setBrandImage(toCropImage);
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              label={"Save"}
              onPress={() => {
                showCroppedImage();
                setIsCropModalVisible(false);
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              type={"error"}
              isSecondary={true}
              label={"Cancel"}
              onPress={() => setIsCropModalVisible(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="business-signup-page-wrapper">
      <CentralCustomModal
        title={"Crop image"}
        show={isCropModalVisible}
        onClose={() => setIsCropModalVisible(false)}
        showDividerTop={false}
        renderContent={() => renderCropEventBanner()}
        isDynamicSize={false}
      />

      <div className="business-signup-inner-container">
        <BusinessSignUpMaskMiddle
          height={150}
          style={{ position: "absolute", top: 0, zIndex: 3 }}
          className="businessSignUp-top-bg"
        />
        <BusinessSignUpMaskLeft
          style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }}
          className="businessSignUp-top-bg2"
        />
        <BusinessSignUpMaskRight
          style={{ position: "absolute", right: 0, top: 0, zIndex: 2 }}
          className="businessSignUp-top-bg3"
        />
        <div className="businessSignUp-top-plusIcon">
          <HoverBGButton
            style={{
              borderWidth: 6,
              borderColor: "var(--veryLightGrey)",
              zIndex: 7,
            }}
            withBorder={true}
            icon={<UserAddFilled />}
            size={120}
          />
        </div>
        <div className="tittle">
          <Text
            textAlign={"center"}
            variant="m700"
            marginTop={30}
            marginBottom={24}
          >
            Create your business profile
          </Text>
        </div>

        <div className="subTittle">
          <Text textAlign={"center"} variant="r300">
            Your business profile will be displayed on our website.
          </Text>
        </div>

        <div
          style={{ marginTop: 82 }}
          className="about-blockBusinessSignUp about-blockBusinessSignUp-container"
        >
          <Overview
            onBlurInput1={(type, value) => ErrorCheck(type, value)}
            headingTitle={"Overview"}
            vatNumber={vatNumber}
            brandName={brandName}
            brandEmail={brandEmail}
            brandMobileCode={brandMobileCode}
            brandMobileNumber={brandMobileNumber}
            brandUrl={brandUrl}
            brandImage={brandImage}
            businessNameError={businessNameError}
            businessEmailError={emailError}
            businessWebsiteError={websiteError}
            businessLogoError={businessLogoError}
            onBrandNameChange={(value) => {
              setBrandName(value);
              setBusinessNameError(
                value.trim() !== "" ? "" : "Brand name required."
              );
            }}
            onBrandEmailChange={(value) => (
              setEmailError(""), setBrandEmail(value), verifyEmail(value)
            )}
            onBrandMobileCodeChange={(value) => setBrandMobileCode(value)}
            onBrandMobileNumberChange={(value) => setBrandMobileNumber(value)}
            onBrandUrlChange={(value) => (
              setWebsiteError(""), setBrandUrl(value), verifyUrl(value)
            )}
            onBrandImageChange={(value) => {
              if (value) {
                setToCropImage(value);
                setIsCropModalVisible(true);
              } else {
                setBrandImage(value);
              }
              setBusinessLogoError("");
            }}
            onVatNumberChange={(value) => setVatNumber(value)}
          />
        </div>

        <div className="socialLinks-blockBusinessSignUp socialLinks-blockBusinessSignUp-container">
          <SocialLink
            type={"businessSignUp"}
            data={socialList}
            onSocialListChange={(value) => setSocialList(value)}
          />
        </div>

        <div className="saveCont-btn-BusinessSignUp">
          <ButtonPrimary
            style={{ marginBottom: 64, marginTop: 64 }}
            width={328}
            label={"Save and continue"}
            onPress={() => onSubmitSignUp()}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BusinessSignUp;
