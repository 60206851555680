import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../Component/CreateEvent/EventDetails/EventDetails.css";
import InputField from "../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../Common/Buttons/ButtonPrimary/ButtonPrimary";
import DropdownInput from "../../Component/Common/DropdownInput/DropdownInput";
import { ReactComponent as BinFilled } from "../../assets/svgs/BinFilled.svg";
import { ReactComponent as EditFilled } from "../../assets/svgs/EditFilled.svg";
import ButtonPill from "../Common/Buttons/ButtonPill";
import Text from "../Common/Text";

export default function SocialLink({
  data,
  onSocialListChange = () => {},
  type,
  shouldShowSubmitButton,
  shouldShowSummary,
  onEditClicked,
  onSaveClicked,
  onDiscardClicked,
  gateKeeperUserName,
  gateKeeperPassword,
  onGateKeeperUserNameChange,
  onGateKeeperPasswordChange,
  checkValidationUserName,
  gateKeeperUserNameAssistiveText,
  gateKeeperPasswordAssistiveText,
}) {
  const { pathname } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const assetOptions = [
    { name: "Select an option" },
    { name: "Facebook" },
    { name: "X" },
    { name: "Instagram" },
    { name: "LinkedIn" },
    { name: "TikTok" },
    { name: "YouTube" },
  ];

  const patterns = {
    facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9._-]+(\/)?(\?.*)?$/,
    x: /^(https?:\/\/)?(www\.)?(x|twitter)\.com\/[A-Za-z0-9._-]+(\/)?(\?.*)?$/,
    instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+(\/)?(\?.*)?$/,
    linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in\/)?[A-Za-z0-9._-]+(\/)?(\?.*)?$/,
    tiktok: /^(https?:\/\/)?(www\.)?tiktok\.com\/@?[A-Za-z0-9._-]+(\/)?(\?.*)?$/,
    youtube: /^(https?:\/\/)?(www\.)?(youtube\.com\/(channel|c|user)?\/?[A-Za-z0-9._-]+|youtu\.be\/[A-Za-z0-9._-]+)\/?$/,
  };

  function getValidURL(value) {
    if (!value.startsWith("http://") && !value.startsWith("https://")) {
      value = "https://" + value;
    }
    return new URL(value);
  }

  function convertToLinkedString(url) {
    try {
      let parsedUrl = new URL(url);
      let pathParts = parsedUrl.pathname.split("/");
      let username = pathParts.pop();
      return `Linked to @${username}`;
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  }

  const validateURL = (value, host, index) => {
    let tempArray = [...data];
    if (value === "") {
      tempArray[index].assistiveText = "";
      tempArray[index].descriptiveText = "";
      tempArray[index].isSaved = true;
      onSocialListChange(tempArray);
      return;
    }
    try {
      let url = getValidURL(value);
      switch (host) {
        case "X":
          if (!patterns.x.test(url)) {
            tempArray[index].assistiveText = "Invalid Twitter URL";
            tempArray[index].descriptiveText = "";
          } else {
            tempArray[index].assistiveText = "";
            tempArray[index].descriptiveText = convertToLinkedString(url);
          }
          break;
        case "Instagram":
          if (!patterns.instagram.test(url)) {
            tempArray[index].assistiveText = "Invalid Instagram URL";
            tempArray[index].descriptiveText = "";
          } else {
            tempArray[index].assistiveText = "";
            tempArray[index].descriptiveText = convertToLinkedString(url);
          }

          break;
        case "LinkedIn":
          if (!patterns.linkedin.test(url)) {
            tempArray[index].assistiveText = "Invalid LinkedIn URL";
            tempArray[index].descriptiveText = "";
          } else {
            tempArray[index].assistiveText = "";
            tempArray[index].descriptiveText = convertToLinkedString(url);
          }
          break;
        case "Facebook":
          if (!patterns.facebook.test(url)) {
            tempArray[index].assistiveText = "Invalid Facebook URL";
            tempArray[index].descriptiveText = "";
          } else {
            tempArray[index].assistiveText = "";
            tempArray[index].descriptiveText = convertToLinkedString(url);
          }
          break;
        case "TikTok":
          if (!patterns.tiktok.test(url)) {
            tempArray[index].assistiveText = "Invalid TikTok URL";
            tempArray[index].descriptiveText = "";
          } else {
            tempArray[index].assistiveText = "";
            tempArray[index].descriptiveText = convertToLinkedString(url);
          }
          break;
        case "YouTube":
          if (!patterns.youtube.test(url)) {
            tempArray[index].assistiveText = "Invalid YouTube URL";
            tempArray[index].descriptiveText = "";
          } else {
            tempArray[index].assistiveText = "";
            tempArray[index].descriptiveText = convertToLinkedString(url);
          }
          break;
        default:
          break;
      }
      console.log("tempArray", tempArray);
      tempArray[index].isSaved = true;
      onSocialListChange(tempArray);
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  };

  return (
    <div className="event-about-div-bs">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginBottom: 40,
        }}
      >
        <div
          style={{ width: "100%" }}
          className="generic-common-row-div-space-between"
        >
          <Text marginRight={16} variant={"m500"}>
            {type !== "entrance"
              ? "Social links"
              : "Entrances & Scanning Points"}
          </Text>

          {shouldShowSummary && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => onEditClicked()}
            >
              <Text color={"var(--midBlue)"} marginRight={8} variant={"m300"}>
                Edit
              </Text>
              <EditFilled height={24} width={24} />
            </div>
          )}
        </div>

        {!shouldShowSummary && type !== "businessSignUp" && (
          <div className="TextCreateEntrance">
            <Text
              color={"var(--midnightBlue)"}
              variant={"r300"}
              marginTop={12}
              marginBottom={40}
              display={"block"}
              // fontSize={"16"}
            >
              {
                "Create entrances and scanning points where ever you need in your venue.  These can then be selected in our Venue app so you can have staff members scanning QR codes at different areas in your venue."
              }
            </Text>
          </div>
        )}

        {!shouldShowSummary && type !== "businessSignUp" && (
          <div
            style={{
              height: 1,
              marginLeft: -24,
              marginRight: -24,
              width: "calc(100% + 48px)",
              backgroundColor: "var(--lightGrey)",
            }}
          />
        )}
      </div>

      {shouldShowSummary ? (
        data.length > 0 ? (
          data.map((item, ind) => {
            return (
              <div className="SummaryBodyCont">
                <div
                  className="SummaryGroup"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: ind === data.length - 1 ? 0 : 24,
                  }}
                >
                  <div className="SummaryLabel" style={{ width: 226 }}>
                    {/* <Text marginRight={30} width={246} variant={"m300"}> */}
                    <Text width={246} variant={"m300"}>
                      {`Entrance or Scanning Point ${ind + 1}`}
                    </Text>
                  </div>
                  <div className="SummaryDetails">
                    <Text
                      width={1000}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")}
                      variant={"m300"}
                    >
                      {item.name}
                    </Text>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Text color={"var(--grey"} marginRight={30} variant={"m300"}>
            {"No entrances created for this event"}
          </Text>
        )
      ) : (
        data.map((item, index) => {
          return (
            <div
              style={{
                flex: 1,
                borderBottom: type === "entrance" && "none",
                marginBottom: type !== "entrance" && 40,
              }}
              className={`generic-common-column-div ${
                type === "entrance" && `socialLinks-item-BusinessSignUp`
              }`}
            >
              {item?.isSaved && type !== "entrance" && (
                <div
                  style={{ alignSelf: "flex-end", cursor: "pointer" }}
                  onClick={() => {
                    const newArray = [
                      ...data.slice(0, index),
                      ...data.slice(index + 1),
                    ];
                    onSocialListChange(newArray);
                  }}
                  className="generic-common-row-div socialLinks-BusinessSignUp-delete"
                >
                  <div className="socialLinks-BusinessSignUp-delete-inner">
                    <Text
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--red")}
                      marginRight={8}
                      marginTop={3}
                      variant="m300"
                    >
                      Delete
                    </Text>
                    <BinFilled
                      fill={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--red")}
                    />
                  </div>
                </div>
              )}

              <div
                style={{ width: "100%" }}
                className="generic-common-row-div-space-between socialLinks-BusinessSignUp-inner"
              >
                <div
                  className="socialLinks-BusinessSignUp-inner-lft"
                  style={{
                    width: type === "entrance" && "100%", // was opened
                    paddingRight: 0,
                    height: "80px",
                    marginBottom: type === "entrance" ? 24 : null,
                  }}
                >
                  <div
                    class="SocialLinkFields"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // marginTop: 40,
                      //flex: 1 / 2,
                      //width:"50%",
                      //paddingRight: 32,
                    }}
                  >
                    <div
                      class="CommonFormGroup"
                      style={{
                        width: type === "entrance" ? "50%" : "100%", //was 50%
                        paddingRight: 32,
                        // marginBottom:
                        //   type === "entrance" && data.length - 1 !== index
                        //     ? 24
                        //     : 24,
                      }}
                    >
                      <Text variant="m300" marginBottom={8}>
                        {type !== "entrance"
                          ? "Social media network"
                          : `Entrance or Scanning Point ${index + 1}`}
                      </Text>

                      {type !== "entrance" ? (
                        <DropdownInput
                          eventCategory={item.name || "Select an option"}
                          data={assetOptions}
                          onSelectedItem={(value) => {
                            let tempArray = [...data];
                            tempArray[index].name = value;
                            validateURL(tempArray[index].url, value, index);
                            onSocialListChange(tempArray);
                          }}
                          editable={true}
                        />
                      ) : (
                        <InputField
                          style={{ marginBottom: "80px" }}
                          inputValue={item?.name}
                          verifyNotNeed={true}
                          placeholder={
                            type !== "entrance"
                              ? "Enter URL"
                              : "Enter entrance name (ie Main Entrance)"
                          }
                          secureTextEntry={false}
                          editable={true}
                          type={"eventName"}
                          withOutIcon={true}
                          isError={false}
                          onChangeValue={(text) => {
                            let tempArray = [...data];
                            tempArray[index].name = text;
                            onSocialListChange(tempArray);
                          }}
                          onBlur={(value) => {}}
                          assistive={""}
                        />
                      )}
                    </div>

                    {type === "entrance" && (
                      <div
                        onClick={() => {
                          const newArray = [
                            ...data.slice(0, index),
                            ...data.slice(index + 1),
                          ];
                          onSocialListChange(newArray);
                        }}
                        style={{
                          alignSelf: type !== "entrance" && "flex-end",
                          marginTop: type === "entrance" ? -40 : null,
                        }}
                        className="generic-common-row-div socialLinks-BusinessSignUp-delete"
                      >
                        <div className="socialLinks-BusinessSignUp-delete-inner">
                          <Text
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--red")}
                            marginRight={8}
                            variant="m300"
                            marginTop={3}
                          >
                            {type === "entrance" ? "Remove" : "Delete"}
                          </Text>
                          <BinFilled
                            fill={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--red")}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {type !== "entrance" && (
                  <div className="socialLinks-BusinessSignUp-inner-rht">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginTop: 40,
                        flex: 1 / 2,
                        height: "80px",
                      }}
                    >
                      <Text variant="m300" marginBottom={8}>
                        URL
                      </Text>

                      <InputField
                        inputValue={item?.url}
                        verifyNotNeed={true}
                        placeholder={"Enter URL"}
                        secureTextEntry={false}
                        editable={true}
                        type={"eventName"}
                        withOutIcon={true}
                        isError={false}
                        onChangeValue={(text) => {
                          let tempArray = [...data];
                          tempArray[index].url = text;
                          onSocialListChange(tempArray);
                        }}
                        onBlur={(value) => validateURL(value, item.name, index)}
                        assistive={item.assistiveText ? item.assistiveText : ""}
                        descriptive={
                          item.descriptiveText ? item.descriptiveText : ""
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}

      {(!shouldShowSummary || type === "businessSignUp") && (
        <div
          className="EntranceBtnCont"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: type !== "entrance" ? 8 : 24,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={
              type !== "entrance"
                ? "Add another social network"
                : "Add another entrance"
            }
            onPress={() => {
              let tempArray = [...data];
              if (tempArray.length > 0) {
                tempArray[tempArray.length - 1].isSaved = true;
              }
              tempArray.push({
                name: type !== "entrance" ? "Select an option" : "",
                url: "",
                isSaved: false,
              });
              onSocialListChange(tempArray);
            }}
            width={328}
          />
        </div>
      )}

      {/* {!shouldShowSummary && type === "entrance" && (
        <div style={{ marginBottom: 16 }} className="generic-common-column-div">
          <div
            style={{
              height: 1,
              marginLeft: -24,
              marginRight: -24,
              width: "calc(100% + 48px)",
              backgroundColor: "var(--lightGrey)",
              marginTop: 32,
              marginBottom: 32,
            }}
          />

          <Text marginRight={8} variant={"m300"}>
          TwoTixx venue app log in details.
          </Text>

          <ButtonPill
            style={{ marginTop: 16 }}
            type={"info"}
            varient={"small"}
            label={
              // pathname?.includes("/EventDetails")
              //   ? "Updating the username or password here is for the Venue App only and will not update your Box Office credentials"
              //   : "Use these login details to sign in to the Twotixx Venue app to scan tickets for this event."
              `Your log in credentials for Venue app have been automatically created when you registered to use Box Office. Please use these to login to venue app.\n\n If you require another set of log in credentials for venue app for another member of staff for this event - please create this here.`
            }
          />

          <div
            style={{
              width: "100%",
              marginTop: "24px",
              alignItems: "flex-start",
            }}
            className="generic-common-row-div-space-between"
          >
            <div
              style={{ width: "50%", paddingRight: 32 }}
              className="CommonFormGroup"
            >
              <Text variant="m300" marginBottom={8}>
                Username*
              </Text>

              <InputField
                shouldShowSuceessIcon={true}
                inputValue={gateKeeperUserName}
                verifyNotNeed={true}
                shouldShowErrorOnAssistive={true}
                placeholder={"Create Username"}
                secureTextEntry={false}
                editable={true}
                type={"eventName"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onGateKeeperUserNameChange(text)}
                onBlur={(value) => checkValidationUserName(value)}
                assistive={
                  gateKeeperUserNameAssistiveText
                    ? gateKeeperUserNameAssistiveText
                    : ""
                }
              />
            </div>
            <div
              style={{ width: "50%", paddingLeft: 32 }}
              className="CommonFormGroup"
            >
              {showPassword || pathname === "/CreateEvent" ? (
                <div className="generic-common-column-div">
                  <Text variant="m300" marginBottom={8}>
                    Password*
                  </Text>

                  <InputField
                    withOutIcon={true}
                    verifyNotNeed={true}
                    shouldShowSuceessIcon={true}
                    inputValue={gateKeeperPassword}
                    placeholder={"Create Password"}
                    shouldShowErrorOnAssistive={true}
                    secureTextEntry={true}
                    editable={true}
                    isError={false}
                    onChangeValue={(text) => onGateKeeperPasswordChange(text)}
                    onBlur={(value) => {}}
                    assistive={
                      gateKeeperPasswordAssistiveText
                        ? "*Password is required"
                        : ""
                    }
                  />

                  {pathname === "/CreateEvent" ? null : (
                    <ButtonPrimary
                      style={{
                        alignSelf: "flex-end",
                        display: "flex",
                        marginTop: 24,
                      }}
                      label={"Update password"}
                      onPress={() => setShowPassword(false)}
                    />
                  )}
                </div>
              ) : (
                <div className="generic-common-column-div">
                  <ButtonPrimary
                    style={{
                      alignSelf: "flex-end",
                      display: "flex",
                      marginTop: "30px",
                    }}
                    label={"Change password"}
                    onPress={() => setShowPassword(true)}
                    width={328}
                  />
                </div>
              )}
            </div>
          </div>
          
        </div>
      )} */}

      {/* {!shouldShowSummary && type !== "businessSignUp" && (
        <div
          className="EntranceBtnCont"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: type !== "entrance" ? 8 : 40,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={
              type !== "entrance"
                ? "Add another social network"
                : "Add another entrance"
            }
            onPress={() => {
              let tempArray = [...data];
              if (tempArray.length > 0) {
                tempArray[tempArray.length - 1].isSaved = true;
              }
              tempArray.push({
                name: type !== "entrance" ? "Select an option" : "",
                url: "",
                isSaved: false,
              });
              onSocialListChange(tempArray);
            }}
            width={328}
          />
        </div>
      )} */}

      {shouldShowSubmitButton && !shouldShowSummary ? (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 56,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      ) : null}
    </div>
  );
}
