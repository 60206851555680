import React, { useRef } from "react";
import { FileDrop } from "react-file-drop";
import "./AccountDetails.css";
import "../../Pages/SignUp/BusinessSignUp.css";
import "../BusinessSignUp/Overview.css";
import "../../Component/CreateEvent/EventDetails/EventDetails.css";
import InputField from "../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../Common/Buttons/ButtonPrimary/ButtonPrimary";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import SocialLink from "../../Component/BusinessSignUp/SocialLink";
import { ReactComponent as VerifiedWhiteFilled } from "../../assets/svgs/VerifiedWhiteFilled.svg";
import { ReactComponent as NotVerify } from "../../assets/svgs/NotVerify.svg";
import { ReactComponent as GalleryAddFilled } from "../../assets/svgs/GalleryAddFilled.svg";
import { ReactComponent as BinFilled } from "../../assets/svgs/BinFilled.svg";
import Text from "../Common/Text";

export default function AccountDetails({
  mode,
  name,
  email,
  businessName,
  businessEmail = '',
  businessMobile,
  businessMobileCode,
  businessWebsite,
  onEditDetailsBtnClicked,
  onSaveChangesBtnClicked,
  isAccountVerified,
  brandImage,
  onBrandImageChange,
  onNameChange,
  onEmailChange,
  onBusinessNameChange,
  onBusinessEmailChange,
  onBusinessMobileChange,
  onBusinessWebsiteChange,
  businessNameError,
  businessProfileImageError,
  nameError,
  emailError,
  socialList,
  setSocialList = () => {},
}) {
  const hiddenFileInput = useRef(null);
  const dragInputRef = useRef(null);

  const onDragInputChange = (event) => {
    console.log(event, "event.target.files[0]");
    if (event.target.files && event.target.files[0]) {
      onBrandImageChange(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    convertAndSetLogo(event, fileUploaded);
    if (event.target.files && event.target.files[0]) {
      onBrandImageChange(URL.createObjectURL(event.target.files[0]));
    }
    console.log(fileUploaded, "fileUploaded");
  };

  const convertAndSetLogo = (e, file) => {
    // e.preventDefault();
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const onTargetClick = () => {
    dragInputRef.current.click();
  };

  return (
    <div className="account-verified-container">
      <div
        style={{ width: "100%" }}
        className="generic-common-row-div-space-between"
      >
        <Text variant={"m500"}>Your details</Text>

        <div className="generic-common-row-div">
          <Text
            marginRight={8}
            color={isAccountVerified ? "var(--midBlue)" : "var(--orange)"}
            variant={"m300"}
          >
            {isAccountVerified ? "Verified" : "Not Verified"}
          </Text>
          {isAccountVerified ? <VerifiedWhiteFilled /> : <NotVerify />}
        </div>
      </div>

      {isAccountVerified ? (
        <div className="account-not-verify-container">
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div className="generic-common-row-div">
              <HoverBGButton
                backgroundHover={"var(--twotix-light-blue)"}
                icon={<VerifiedWhiteFilled />}
                size={48}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 16,
                }}
              >
                <Text variant={"m300"}>
                  {"Your details have been verified "}
                </Text>
                <Text marginTop={8} variant={"r300"}>
                  {
                    "These details have been verified. If you want to edit your details, you will need to reverify your account."
                  }
                </Text>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div style={{ marginTop: 40 }} className="generic-common-divider" />

      <Text alignSelf={"start"} marginTop={40} variant={"m500"}>
        Personal details
      </Text>
      <div
        style={{ marginTop: 40, width: "100%" }}
        className="generic-common-row-div personal-details-block"
      >
        <div
          style={{ width: "50%" }}
          className="generic-common-column-div personal-details-lft"
        >
          <Text
            marginBottom={mode === "view" ? 0 : 8}
            variant={mode === "view" ? "m400" : "m300"}
            alignSelf={"start"}
          >
            {mode === "view" ? "Name" : "Name*"}
          </Text>
          {mode === "view" ? (
            <Text
              alignSelf={"start"}
              color={"var(--midBlue)"}
              marginTop={8}
              variant={"m400"}
            >
              {name}
            </Text>
          ) : (
            <InputField
              verifyNotNeed={true}
              editable={true}
              inputValue={name}
              placeholder={"Enter full name"}
              secureTextEntry={false}
              type={"genericInput"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => onNameChange(text)}
              onBlur={(value) => {}}
              warningDescriptive={
                isAccountVerified ? "Editing will require you to re-verify your details." : null
              }
              assistive={nameError}
            />
          )}
        </div>
        {mode === "view" && (
          <div
            style={{ width: "50%" }}
            className="generic-common-column-div personal-details-rht"
          >
            <Text alignSelf={"start"} variant={"m400"}>
              Email address
            </Text>

            <Text
              alignSelf={"start"}
              color={"var(--midBlue)"}
              marginTop={8}
              variant={"m400"}
            >
              {email}
            </Text>
          </div>
        )}
      </div>

      {mode === "edit" && (
        <div
          style={{ width: "50%", marginTop: 40 }}
          className="generic-common-column-div personal-details-email"
        >
          <Text marginBottom={8} alignSelf={"start"} variant={"m300"}>
            Email address*
          </Text>
          {mode === "view" ? (
            <Text
              alignSelf={"start"}
              color={"var(--midBlue)"}
              marginTop={8}
              variant={"m400"}
            >
              {email}
            </Text>
          ) : (
            <InputField
              verifyNotNeed={true}
              editable={true}
              inputValue={email}
              placeholder={"Enter full name"}
              secureTextEntry={false}
              type={"genericInput"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => onEmailChange(text)}
              onBlur={(value) => {}}
              // warningDescriptive={
              //   isAccountVerified ? "Editing will require you to re-verify your details." : null
              // }
              assistive={emailError}
            />
          )}
        </div>
      )}

      <div style={{ marginTop: 30 }} className="generic-common-divider" />

      <Text alignSelf={"start"} marginTop={40} variant={"m500"}>
        Business details
      </Text>

      <div
        className="businessDetails-block"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: 40,
        }}
      >
        <div
          className="businessDetails-lft"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div style={{ width: "100%" }} className="generic-common-column-div">
            <Text
              alignSelf={"start"}
              marginBottom={mode === "view" ? 0 : 8}
              variant={mode === "view" ? "m400" : "m300"}
            >
              {mode === "view" ? "Business name" : "Brand/Business name*"}
            </Text>
            {mode === "view" ? (
              <Text
                alignSelf={"start"}
                color={"var(--midBlue)"}
                marginTop={8}
                variant={"m400"}
              >
                {businessName}
              </Text>
            ) : (
              <InputField
                verifyNotNeed={true}
                editable={true}
                inputValue={businessName}
                placeholder={"Enter full name"}
                secureTextEntry={false}
                type={"genericInput"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onBusinessNameChange(text)}
                onBlur={(value) => {}}
                descriptive={
                  "This will display on your public promoter profile."
                }
                assistive={businessNameError}
              />
            )}
          </div>
          <div
            style={{ width: "100%", marginTop: 16 }}
            className="generic-common-column-div"
          >
            <Text
              alignSelf={"start"}
              marginBottom={mode === "view" ? 0 : 8}
              variant={mode === "view" ? "m400" : "m300"}
            >
              Business email address
            </Text>
            {mode === "view" ? (
              <Text
                alignSelf={"start"}
                color={mode === "view" && businessEmail?.trim() === '' ? "var(--red)"  : "var(--midBlue)"}
                marginTop={8}
                variant={"m400"}
              >
                {mode === "view" && businessEmail?.trim() === '' ? 'Click edit details to complete' : businessEmail}
              </Text>
            ) : (
              <InputField
                verifyNotNeed={true}
                editable={true}
                inputValue={businessEmail}
                placeholder={"Enter email address"}
                secureTextEntry={false}
                type={"genericInput"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onBusinessEmailChange(text)}
                onBlur={(value) => {}}
                descriptive={
                  "This will display on your public promoter profile."
                }
              />
            )}
          </div>

          <div
            style={{ width: "100%", marginTop: 16 }}
            className="generic-common-column-div"
          >
            <Text
              alignSelf={"start"}
              marginBottom={mode === "view" ? 0 : 8}
              variant={mode === "view" ? "m400" : "m300"}
            >
              Business contact number
            </Text>
            {mode === "view" ? (
              <Text
                alignSelf={"start"}
                color={"var(--midBlue)"}
                marginTop={8}
                variant={"m400"}
              >
                {`${businessMobileCode} ${businessMobile}`}
              </Text>
            ) : (
              <div className="generic-common-column-div">
                <div className="generic-common-row-div">
                  <div style={{ width: 90, marginRight: 4 }}>
                    <InputField
                      maxLength={4}
                      verifyNotNeed={true}
                      editable={false}
                      inputValue={businessMobileCode}
                      placeholder={""}
                      secureTextEntry={false}
                      type={"email"}
                      withOutIcon={true}
                      isError={false}
                      onChangeValue={(text) => {}}
                      onBlur={(value) => {}}
                    />
                  </div>
                  <InputField
                    verifyNotNeed={true}
                    editable={true}
                    inputValue={businessMobile}
                    placeholder={"Enter contact number"}
                    secureTextEntry={false}
                    type={"genericInput"}
                    withOutIcon={true}
                    isError={false}
                    onChangeValue={(text) => onBusinessMobileChange(text)}
                    onBlur={(value) => {}}
                  />
                </div>
                <Text marginTop={8} variant={"label"} color={"var(--grey)"}>
                  {"This will display on your public promoter profile."}
                </Text>
              </div>
            )}
          </div>
          <div
            style={{ width: "100%", marginTop: 16 }}
            className="generic-common-column-div"
          >
            <Text
              alignSelf={"start"}
              marginBottom={mode === "view" ? 0 : 8}
              variant={mode === "view" ? "m400" : "m300"}
            >
              Website address
            </Text>
            {mode === "view" ? (
              <Text
                alignSelf={"start"}
                color={mode === "view" && businessWebsite?.trim() === '' ? "var(--red)" : "var(--midBlue)"}
                marginTop={8}
                variant={"m400"}
              >
                {mode === "view" && businessWebsite?.trim() === '' ? 'Click edit details to complete' : businessWebsite}
              </Text>
            ) : (
              <InputField
                verifyNotNeed={true}
                editable={true}
                inputValue={businessWebsite}
                placeholder={"Enter URL"}
                secureTextEntry={false}
                type={"genericInput"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onBusinessWebsiteChange(text)}
                onBlur={(value) => {}}
                descriptive={
                  "This will display on your public promoter profile."
                }
              />
            )}
          </div>
        </div>
        <div
          className="businessDetails-rht"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingLeft: 40,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Profile image*
          </Text>
          <div className="banner-container-img-bs">
            {brandImage === "" ? (
              <FileDrop
                onFrameDragEnter={(event) =>
                  console.log("onFrameDragEnter", event)
                }
                onFrameDragLeave={(event) =>
                  console.log("onFrameDragLeave", event)
                }
                onFrameDrop={(event) => console.log("onFrameDrop", event)}
                onDragOver={(event) => console.log("onDragOver", event)}
                onDragLeave={(event) => console.log("onDragLeave", event)}
                onDrop={(files, event) => (
                  event.preventDefault(),
                  onBrandImageChange(URL.createObjectURL(files[0]))
                )}
                onTargetClick={() => console.log("onTargetClick!!!!!!!!!!!!")}
              >
                <input
                  onChange={onDragInputChange}
                  ref={dragInputRef}
                  type="file"
                  className="hidden"
                />
                <div className="banner-container-overview">
                  <HoverBGButton
                    icon={<GalleryAddFilled height={24} width={24} />}
                    size={48}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "start",
                      paddingLeft: 21,
                      paddingRight: 16,
                      marginTop: 8,
                    }}
                  >
                    <Text cursor={"pointer"}
                      onClick={() => hiddenFileInput.current.click()}
                      textAlign={"center"}
                      width={"100%"}
                      underline={true}
                      variant="m300"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")}
                    >
                      Click to upload
                    </Text>
                    <Text textAlign={"start"} width={"55%"} variant="m300">
                      or drag
                    </Text>
                  </div>
                  <Text variant="m300">and drop</Text>
                  <Text
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--grey")}
                    variant="r200"
                    marginTop={16}
                  >
                    JPG (max. 600 x 600px)
                  </Text>

                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />
                </div>
              </FileDrop>
            ) : (
              <div className="banner-container-img-bs">
                <img
                  content="center"
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 4,
                    alignSelf: "stretch",
                    objectFit: "cover",
                  }}
                  src={brandImage}
                />
                {mode !== "view" && <div style={{ position: "absolute", right: 14, marginTop: 16 }}>
                  <HoverBGButton
                    icon={
                      <BinFilled
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        height={16}
                        width={16}
                      />
                    }
                    size={32}
                    backgroundHover={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--white")}
                    onPress={() => onBrandImageChange("")}
                  />
                </div>}
              </div>
            )}
          </div>
          {businessProfileImageError ? (
            <Text marginTop={8} variant={"label"} color={"var(--red)"}>
              {businessProfileImageError}
            </Text>
          ) : null}
        </div>
        
      </div>

      {mode === "edit" ? <div style= {{maxWidth: '100%', marginTop: '32px'}} className="socialLinks-blockBusinessSignUp socialLinks-blockBusinessSignUp-container">
                  <SocialLink
                    type={"businessSignUp"}
                    data={socialList}
                    onSocialListChange={(value) => setSocialList(value)}
                  />
                </div>
              :
              <div style={{ width: '50%', marginTop: 0 }}> 

              {socialList?.length > 0 &&
                socialList?.map((item, index) => {
                  return (
               <div
              style={{ width: "100%", marginTop: 16 }}
              className="generic-common-column-div"
            >
              
              <Text
                alignSelf={"start"}
                marginBottom={mode === "view" ? 0 : 8}
                variant={mode === "view" ? "m400" : "m300"}
              >
                {item?.name}
              </Text>
              
                <Text
                  alignSelf={"start"}
                  color={item?.url?.trim() === '' ? "var(--red)" : "var(--midBlue)"}
                  marginTop={8}
                  variant={"m400"}
                >
                  {item?.url?.trim() === '' ? 'Click edit details to complete' : item?.url}
                </Text>
              
            </div>)})}
            </div>}

      {mode === "edit" ? (
        <div
          style={{ marginTop: 40 }}
          className="generic-common-row-centerd-div"
        >
          <ButtonPrimary
            style={{ marginRight: 24 }}
            width={360}
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onEditDetailsBtnClicked(false)}
          />
          <ButtonPrimary disabled={!socialList.every((item) => item.assistiveText === "")}
            width={360}
            isSecondary={false}
            label={"Save changes"}
            onPress={() => onSaveChangesBtnClicked()}
          />
        </div>
      ) : (
        <ButtonPrimary
          style={{ marginTop: 40, alignSelf: "center" }}
          width={360}
          isSecondary={true}
          label={"Edit details"}
          onPress={() => onEditDetailsBtnClicked(true)}
        />
      )}
    </div>
  );
}
