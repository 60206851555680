import React, { useState } from "react";
import Text from "../Text";
import "./CentralModal";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";
import { ReactComponent as CheckBoxCheckedCircle } from "../../../assets/svgs/CheckBoxCheckedCircle.svg";
import { ReactComponent as CheckBoxUncheckCircle } from "../../../assets/svgs/CheckBoxUncheckCircle.svg";
import { ReactComponent as CloseNew } from "../../../assets/svgs/CloseNew.svg";

const OrderCancelModal = ({
  isOpen,
  onClose,
  orderDetailsItem,
  selectedOrderItems,
  orderId,
  isCancelAndRefund,
  currencySymbol,
  onCancelButtonClicked = () => { },
}) => {
  const [isConfiremd, setIsConfirmed] = useState(false);

  if (!isOpen) return null;

  const renderSummaryList = ({ item, index }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div className="generic-common-row-div-space-between" key={index}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              color={"var(--midnightBlue)"}
              variant={
                index === 0 || index === selectedOrderItems.length
                  ? "b200"
                  : "m200"
              }
            >
              {index === 0
                ? "Ticket"
                : index === selectedOrderItems.length
                ? "Total"
                : `${item?.eventTicketType?.name || "-"}`}
            </Text>
            {index !== 0 && index !== selectedOrderItems.length ? (
              <Text
                color={"var(--midnightBlue)"}
                variant={"r100"}
                marginTop={4}
              >
                {index === 0
                  ? "Ticket"
                  : index === selectedOrderItems.length
                  ? "Total"
                  : `${
                      (!item?.eventTicketType?.isAddOn ? "Ticket" : "Add-on") ||
                      "-"
                    }`}
              </Text>
            ) : null}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 24,
            }}
          >
            <Text
              variant={
                index === 0 || index === selectedOrderItems.length
                  ? "b200"
                  : "m200"
              }
            >
              {index === 0
                ? "Quantity"
                : index === selectedOrderItems.length
                ? selectedOrderItems?.reduce(
                    (accumulator, item) => accumulator + (item.quantity ?? 0),
                    0
                  ) || "-"
                : `${item?.quantity || "-"}`}
            </Text>
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 24,
            }}
          >
            <Text
              textWrap={"noWrap"}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midnightBlue")}
              variant={
                index === 0 || index === selectedOrderItems.length
                  ? "b200"
                  : "m200"
              }
            >
              {index === 0
                ? "Price"
                : index === selectedOrderItems.length
                ? `${currencySymbol}${selectedOrderItems
                    ?.reduce(
                      (accumulator, item) => accumulator + (item.price ?? 0),
                      0
                    )
                    ?.toFixed(2)}`
                : item?.price
                ? `${currencySymbol}${item?.price}`
                : "-"}
            </Text>
          </div>
        </div>
        {index !== selectedOrderItems.length && (
          <div
            style={{
              height: 1,
              marginTop: 12,
              marginBottom: 12,
              marginLeft: -24,
              marginRight: -24,
              backgroundColor: "var(--lightGrey)",
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div
      style={{ backgroundColor: "#0D2244F2" }}
      className="central-modal-overlay"
    >
      <div className="order-cancelling-modal-content">
        <header className="order-cancelling-modal-header">
          {/* <h2>Cancel & Refund Summary</h2> */}
          <Text variant={"b400"}>
            {isCancelAndRefund ? "Cancel & Refund Summary" : "Cancel summary"}
          </Text>
          <div
            onClick={onClose}
            style={{
              cursor: "pointer",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
            }}
          >
            <CloseNew />
          </div>
          {/* <button className="order-cancelling-close-btn" onClick={onClose}>×</button> */}
        </header>
        <div
          style={{
            height: 1,
            marginBottom: 24,
            marginLeft: -20,
            marginRight: -20,
            backgroundColor: "var(--lightGrey)",
          }}
        />
        <section className="order-cancelling-modal-body">
          <div
            style={{ gap: "12px" }}
            className="order-cancelling-details-container"
          >
            <div className="order-cancelling-details-row">
              <Text
                textWrap={"noWrap"}
                color={"var(--midBlue)"}
                variant={"b100"}
              >
                {"Order number"}
              </Text>
              <Text variant={"m200"}>{`#${orderId || "-"}`}</Text>
            </div>
            <div className="order-cancelling-details-row">
              <Text
                textWrap={"noWrap"}
                color={"var(--midBlue)"}
                variant={"b100"}
              >
                {"Email address"}
              </Text>
              <Text textWrap={"noWrap"} variant={"m200"}>{`${
                orderDetailsItem?.emailAddress || "-"
              }`}</Text>
            </div>
            {isCancelAndRefund ? (
              <div className="order-cancelling-details-row">
                <Text
                  textWrap={"noWrap"}
                  color={"var(--midBlue)"}
                  variant={"b100"}
                >
                  {"Payment card"}
                </Text>
                <Text textWrap={"noWrap"} variant={"m200"}>
                  {orderDetailsItem?.cardLast4
                    ? `************${orderDetailsItem?.cardLast4}`
                    : "****************"}
                </Text>
              </div>
            ) : null}
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "24px",
              borderRadius: "24px",
              padding: "12px 24px",
              border: "1px solid var(--surfaceDisabled)",
              overflowY: "scroll",
              maxHeight: "290px",
              // zIndex: 309138,
            }}
            className="generic-common-column-div central-modal-contentPopup-bottomList"
          >
            {[...selectedOrderItems, {}].map((item, index) => {
              return renderSummaryList({ item, index });
            })}
          </div>

          {!isCancelAndRefund ? (
            <div
              onClick={() => {}}
              style={{
                marginTop: 24,
                backgroundColor: "var(--surface-warning-light)",
                padding: "12px 12px",
                borderRadius: 24,
              }}
              className="generic-common-row-div central-modal-contentPopup-check"
            >
              <Text
                color={"var(--text-body-warning)"}
                textAlign={"center"}
                variant={"r200"}
              >
                {
                  "Canceling will remove items from the purchaser's Twotixx account without issuing a refund. To provide a refund, use the 'Cancel and Refund' option."
                }
              </Text>
            </div>
          ) : null}

          <div
            onClick={() => setIsConfirmed(!isConfiremd)}
            style={{
              marginTop: 24,
              backgroundColor: isConfiremd
                ? "var(--surfaceSuccessLight)"
                : "var(--surface-secondary)",
              padding: "12px 12px",
              borderRadius: 24,
              alignItems: 'center'
            }}
            className="generic-common-row-div central-modal-contentPopup-check"
          >
            <div
              style={{
                width: 38,
                height: 38,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {isConfiremd ? (
                <CheckBoxCheckedCircle width={24} height={24} />
              ) : (
                <CheckBoxUncheckCircle width={24} height={24} />
              )}
            </div>
            <Text marginTop={-2} marginLeft={12} variant={"r200"}>
              {
                "By ticking this box, you confirm agreement with the details above and wish to proceed with the refund and cancellation."
              }
            </Text>
          </div>
          <div
            style={{ marginTop: "24px",
              gap: "16px",
             }}
            className="order-cancelling-details-container"
          >
            <ButtonPrimary
              type={"error"}
              isSecondary={true}
              label={"Cancel"}
              onPress={() => onClose()}
              // style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              disabled={!isConfiremd}
              label={
                isCancelAndRefund ? "Confirm refund" : "Confirm cancellation"
              }
              onPress={() => onCancelButtonClicked()}
              style={{}}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderCancelModal;
